import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as webSocketActions from '../redux/actions/webSocketActions';

import SEO from '../components/SEO';
import TOC from '../components/TOC';

function Terms({ siteConfig }) {
  return (
    <>
      <SEO
        title={'Terms of Service'}
        description={
          'The Terms of Service when using the ' +
          siteConfig.brand +
          ' website.'
        }
      />
      <div className="article-container">
        <h1>
          <div>{' ' + siteConfig.brand + ' '} Terms of Service</div>
        </h1>
        <div className="page-container text-container">
          <div className="page">
            <div className="inner">
              <TOC />
              <div className="article-text">
                <p>Updated January 03, 2022</p>
                <h2>Introduction</h2>
                <p>
                  These Terms of Service (the &ldquo;Terms&rdquo;) explain the
                  agreement you make with {siteConfig.brand} LLC, an Arizona
                  limited liability company (&ldquo;{siteConfig.brand}&rdquo;,
                  which may hereinafter be referred to as &ldquo;us,&rdquo;
                  &ldquo;we,&rdquo; and &ldquo;our&rdquo;) when you access our
                  website at{' '}
                  <a href={'http://www.' + siteConfig.domain} className="link">
                    www.{siteConfig.domain}
                  </a>{' '}
                  and use the products and services displayed, offered or sold
                  by
                  {' ' + siteConfig.brand} (collectively the
                  &ldquo;Site&rdquo;).
                </p>
                <p>
                  We have attempted to provide clear explanations of our Terms
                  so that you can decide for yourself whether to use our Site,
                  or not. If you do not agree to our Terms, you cannot use our
                  Site. If you have questions or concerns about our Terms,
                  please contact us by email at:{' '}
                  <a
                    href={'mailto:support@' + siteConfig.domain}
                    className="link">
                    support@{siteConfig.domain}
                  </a>{' '}
                  or by postal mail at: {siteConfig.brand} LLC - 2525 E
                  Camelback Rod STE 810 - Phoenix, AZ 85016
                </p>
                <p>
                  PLEASE REVIEW THESE TERMS CAREFULLY. BY USING THIS SITE, YOU
                  ARE AGREEING TO BE BOUND BY THESE TERMS, AND ANY OTHER TERMS,
                  POLICIES AND DISCLOSURES INCORPORATED HEREIN BY REFFERENCE.
                </p>

                <h2>General Terms and Understandings</h2>
                <p>
                  In addition to providing general information,{' '}
                  {siteConfig.brand} provides a gateway through which users of
                  our Site can view, select and purchase certain goods or
                  services (the &ldquo;Product&rdquo; or &ldquo;Products&rdquo;)
                  from certain third party providers (the &ldquo;Provider&rdquo;
                  or &ldquo;Providers&rdquo;). {siteConfig.brand} does not
                  represent or imply that the Site contains a complete or
                  comprehensive list or choice of all Providers and all Products
                  available at a particular address. Accordingly, there may be
                  Providers that can provide you with service at your particular
                  address that do not appear on this Site and are not listed as
                  a choice for you to select from. You are in no way obligated
                  to use
                  {' ' + siteConfig.brand} or the Site to choose your Products
                  or Providers.
                </p>
                <p>
                  If you do choose to purchase a Product from a Provider through
                  the Site (the &ldquo;Order&rdquo;), you do so under the
                  specific terms offered by such Provider. You are solely
                  responsible for ensuring you understand those terms before
                  entering into any agreements with such Provider. The
                  contractual agreement for services is between you and the
                  Provider of that Product, not with {siteConfig.brand}.
                </p>
                <p>
                  If you purchase a Product through the Site, the Provider of
                  that Product may require us to share certain personal data
                  about you to complete the Order (see our{' '}
                  <em>
                    <a href="/privacy-policy" className="link">
                      Privacy Policy
                    </a>
                  </em>{' '}
                  for an explanation of the personal data we may collect and
                  share). By using the Site, you grant {siteConfig.brand}{' '}
                  permission to use and share this personal data with the
                  applicable Provider as necessary to complete the Order.
                </p>
                <p>
                  Occasionally a Provider may require {siteConfig.brand} to take
                  additional steps beyond your initial Order before completing
                  the Order. This may include additional verification
                  procedures, credit checks and/or security deposits, as
                  required by the applicable Provider. In the event such
                  additional steps are required, we will notify you via email
                  and you may be asked to agree to additional terms and
                  conditions.
                </p>
                <p>
                  Once your Order is processed, the applicable Provider will
                  communicate with you directly. {siteConfig.brand} will not
                  have access to your Provider account after it has been
                  established. You are solely responsible for any payments due
                  under your agreement with the Provider, as well as terminating
                  any prior accounts that are being replaced or are no longer
                  needed.
                </p>
                <p>
                  You acknowledge and agree that {siteConfig.brand} is acting as
                  an intermediary only and is not a party to any transaction
                  between you and any Provider and that interacting with any
                  Provider accessed through the Site is done so at your own
                  risk. Accordingly, {siteConfig.brand} assumes no
                  responsibility and will have no liability of any kind
                  whatsoever in respect to your dealings with any Provider
                  including with regards to fitness for a particular purpose,
                  delivery, payments, notices, disagreements and the proper and
                  timely delivery of goods or services. {siteConfig.brand} in no
                  way endorses, recommends, and/or bears any responsibility or
                  liability for any products, services or statements made by any
                  Provider. Statements and opinions of Providers are not
                  necessarily those of {siteConfig.brand} or its business
                  partners.
                </p>
                <p>
                  {siteConfig.brand} is not liable for the accuracy or delivery
                  of any Provider bill or billing statements. Any discrepancies
                  or disputes regarding the accuracy of any bill or billing
                  statement must be addressed with the issuing Provider
                  directly. You are responsible for ensuring timely payment of
                  all bills or billing statements.
                </p>
                <p>
                  {siteConfig.brand} assumes no responsibility, and will not be
                  liable, for your failure to take any action or inaction with
                  respect to any Provider account or your failure to receive any
                  information with respect to any Provider account.
                </p>
                <p>
                  These Terms do not alter any liability or obligations that
                  exist between you and the Provider. You agree that your
                  relationship with the Provider is governed solely by the
                  agreement between you and the Provider.
                </p>
                <h2>Your Obligations as a User of the Site</h2>
                <p>
                  Whenever you order or purchase a Product through this Site you
                  are warranting to us and the applicable Provider that you are
                  at least 18 years of age and possess the legal authority to
                  enter into a legally binding contract for the Product in
                  accordance with all terms and conditions herein and as set
                  forth by the Provider. You agree to be financially responsible
                  for your use of and all transactions made in connection with
                  Products ordered or purchased through this Site. You agree
                  that you will be solely responsible for all charges, fees,
                  duties, taxes, and assessments arising out of the purchases
                  and orders you make through this site.
                </p>
                <p>
                  You agree to provide truthful and complete information when
                  using the Site. If you are using the Site on behalf of someone
                  other than yourself, you represent that you are authorized to
                  act for and bind that other person. If you are using the Site
                  on behalf of any entity, you represent that you are authorized
                  to act on the entity&rsquo;s behalf and that the entity agrees
                  to be responsible to us if you or the entity violates these
                  Terms.
                </p>
                <p>
                  When you provide personal information, or otherwise use the
                  Site, you represent and warrant that your use:
                </p>
                <ul>
                  <li>
                    Does not include personal, financial, or confidential
                    information about yourself that you expect to be treated
                    differently than as described in these Terms and the Privacy
                    Policy;
                  </li>
                  <li>
                    Does not include any personal, financial, or confidential
                    information of any other person or entity without their
                    knowledge and prior consent, or that is counterfeit or
                    stolen;
                  </li>
                  <li>
                    Is not for any unlawful purpose or in furtherance of illegal
                    activities;
                  </li>
                  <li>Is not false, fraudulent, or deceptive;</li>
                  <li>
                    Does not breach any of the terms or conditions of these
                    Terms or the Privacy Policy.
                  </li>
                </ul>
                <h2>Your Limited Privilege to Use the Site</h2>
                <p>
                  As long as you comply with these Terms, {siteConfig.brand}{' '}
                  grants you a personal, non-exclusive, non-transferable,
                  limited privilege to enter and use the Site. This limited
                  privilege is only so you may use and enjoy the benefit of the
                  Site as provided by {siteConfig.brand}. You may not use the
                  Site in any manner which could damage, disable, overburden, or
                  impair the Site or interfere with any other users use and
                  enjoyment of the Site. You may not obtain or attempt to obtain
                  any materials or information through any means not
                  intentionally made available or provided for through the Site.
                  This license is revocable at will and may be terminated by{' '}
                  {siteConfig.brand} without notice to you.
                </p>
                <h2>Prices and Availability</h2>
                <p>
                  {siteConfig.brand} does not warrant that information, graphic
                  depictions, product and service descriptions or other content
                  of the Site is accurate, complete, reliable, updated, current,
                  or error-free. Despite our efforts, it is possible that a
                  price for a product or service offered on the Site may be
                  inaccurate or the product or service description may contain
                  an inaccuracy. In the event {siteConfig.brand + ' '}
                  determines that a product or service contains an inaccurate
                  price or description, {siteConfig.brand} reserves the right to
                  take any action it deems reasonable and necessary, in its sole
                  discretion, to rectify the error, including without limitation
                  canceling your order, unless prohibited by law.{' '}
                  {siteConfig.brand} may make improvements or changes to any of
                  its content, information, products, services, or programs
                  described on the Site at any time without notice. You agree to
                  notify {siteConfig.brand} immediately if you become aware of
                  any pricing or descriptive errors or inconsistencies with any
                  products or services you order through the Site and comply
                  with any corrective action taken by {siteConfig.brand}.
                </p>
                <p>
                  Nothing on the Site is an offer or promise by{' '}
                  {siteConfig.brand} to sell a specific product for a specific
                  price or that any Provider will sell any product or service
                  for any purpose or price or on any specific terms.
                </p>
                <h2>Consent to Electronic Communication</h2>
                <p>
                  By using the Site, you are agreeing to the electronic delivery
                  of all documents, disclosures, agreements, statements, and
                  notices that we, or any Product Provider, are required by law
                  to give you. You are further agreeing that information
                  provided to you in this manner will satisfy all legal
                  requirements that communications be in writing. All
                  communications will be deemed delivered and effective when
                  sent to the email address associated with your use of Site or
                  when posted publicly on the Site.
                </p>
                <h2>Third Party Content</h2>
                <p>
                  In using our Site, you may view content provided by third
                  parties, including outbound links to web pages of such
                  parties. {siteConfig.brand + ' '}
                  cannot and does not guarantee, represent or warrant that this
                  third party content is accurate, up to date, legal, safe to
                  open or inoffensive so your viewing of, reliance on, or use of
                  such content is done so at your own risk.
                </p>
                <h2>Outbound Links</h2>
                <p>
                  The Site contains outbound links to websites that are not
                  owned or operated by {siteConfig.brand} and are subject to
                  different terms and conditions. If you follow the outbound
                  links, you will be legally bound by the terms and conditions
                  of the websites to which they lead.&nbsp; Outbound links are
                  provided solely for your convenience and do not imply any
                  affiliation with, or endorsement of, those websites and{' '}
                  {siteConfig.brand} makes no representations whatsoever
                  concerning the accuracy of their content, or any products or
                  services they may offer. You are solely responsible for
                  determining the safety of these websites, and extent to which
                  you interact or use them.
                </p>
                <h2>Inbound Links</h2>
                <p>
                  You are granted a limited, nonexclusive right to create a link
                  to the Site provided that such link is to{' '}
                  <a href={'http://www.' + siteConfig.domain} className="link">
                    www.{siteConfig.domain}
                  </a>{' '}
                  only and does not portray {siteConfig.brand} or any of its
                  products or services in a false, misleading, derogatory, or
                  otherwise defamatory manner. This limited right may be revoked
                  by {siteConfig.brand} at any time for any reason whatsoever.
                  You may not use, frame, or utilize framing techniques to
                  enclose any {siteConfig.brand} trademark, logo or trade name
                  or other proprietary information including the materials found
                  at or on the Site, the content of any text or the
                  layout/design of any page of the Site without the express
                  prior written consent of
                  {' ' + siteConfig.brand} in each instance. In addition, you
                  may not use any meta-tags or any other "hidden text" utilizing
                  a {siteConfig.brand} or
                  {' ' + siteConfig.brand} affiliate name, trademark, or product
                  name without express written consent from {siteConfig.brand}{' '}
                  in each instance.
                </p>
                <h2>Content</h2>
                <p>
                  All text, graphics, user interfaces, visual interfaces,
                  photographs, trademarks, logos, and computer code
                  (collectively, "Content"), including but not limited to the
                  design, structure, selection, coordination, expression, "look
                  and feel" and arrangement of such Content, contained on the
                  Site is owned, controlled or licensed by or to{' '}
                  {siteConfig.brand}, and is protected by trade dress,
                  copyright, patent and trademark laws, and various other
                  intellectual property rights and unfair competition laws.
                </p>
                <p>
                  Except as expressly provided in these Terms, no part of the
                  Site and no Content may be copied, reproduced, republished,
                  uploaded, posted, publicly displayed, encoded, translated,
                  transmitted or distributed in any way (including "mirroring")
                  to any other computer, server, website or other medium for
                  publication or distribution or for any commercial enterprise,
                  without {siteConfig.brand}&rsquo;s express prior written
                  consent.
                </p>
                <p>
                  You may use information on {siteConfig.brand} products and
                  services (such as provider terms and specifications,
                  electricity facts labels, and similar materials) purposely
                  made available by {siteConfig.brand} for downloading from the
                  Site, provided that you (1) not remove any proprietary notice
                  language in all copies of such documents, (2) use such
                  information only for your personal, non-commercial
                  informational purpose and do not copy or post such information
                  on any networked computer or broadcast it in any media, (3)
                  make no modifications to any such information, and (4) not
                  make any additional representations or warranties relating to
                  such documents.
                </p>
                <h2>Your Use of the Site</h2>
                <p>
                  You may not use any "deep-link", "page-scrape", "robot",
                  "spider" or other automatic device, program, algorithm or
                  methodology, or any similar or equivalent manual process, to
                  access, acquire, copy or monitor any portion of the Site or
                  any Content, or in any way reproduce or circumvent the
                  navigational structure or presentation of the Site or any
                  Content, to obtain or attempt to obtain any materials,
                  documents or information through any means not purposely made
                  available through the Site. {siteConfig.brand} reserves the
                  right to bar any such activity.
                </p>
                <p>
                  You may not attempt to gain unauthorized access to any portion
                  or feature of the Site, or any other systems or networks
                  connected to the Site or to any {siteConfig.brand} server, or
                  to any of the services offered on or through the Site, by
                  hacking, password "mining" or any other illegitimate means.
                </p>
                <p>
                  You may not probe, scan or test the vulnerability of the Site
                  or any network connected to the Site, nor breach the security
                  or authentication measures on the Site or any network
                  connected to the Site. You may not reverse look-up, trace or
                  seek to trace any information on any other user of or visitor
                  to the Site, or any other account not owned by you, to its
                  source, or exploit the Site or any service or information made
                  available or offered by or through the Site, in any way where
                  the purpose is to reveal any information, including but not
                  limited to personal identification or information, other than
                  your own information, as provided for by the Site.
                </p>
                <p>
                  You agree that you will not take any action that imposes an
                  unreasonable or disproportionately large load on the
                  infrastructure of the Site or {siteConfig.brand}&rsquo;s
                  systems or networks, or any systems or networks connected to
                  the Site or to {siteConfig.brand}.
                </p>
                <p>
                  You agree not to use any device, software or routine to
                  interfere or attempt to interfere with the proper working of
                  the Site or any transaction being conducted on the Site, or
                  with any other person&rsquo;s use of the Site.
                </p>
                <p>
                  You may not forge headers or otherwise manipulate identifiers
                  in order to disguise the origin of any message or transmittal
                  you send to {siteConfig.brand} on or through the Site or any
                  service offered on or through the Site. You may not pretend
                  that you are, or that you represent, someone else, or
                  impersonate any other individual or entity.
                </p>
                <p>
                  You may not use the Site or any Content for any purpose that
                  is unlawful or prohibited by these Terms, or to solicit the
                  performance of any illegal activity or other activity which
                  infringes the rights of {siteConfig.brand} or others.
                </p>
                <h2>Accounts, Passwords and Security</h2>
                <p>
                  Certain features or services offered on or through the Site
                  may require you to open an account (including setting up an{' '}
                  {siteConfig.brand} ID and password). You are entirely
                  responsible for maintaining the confidentiality of the
                  information you hold for your account, including your
                  password, and for any and all activity that occurs under your
                  account as a result of your failing to keep this information
                  secure and confidential. You agree to notify{' '}
                  {siteConfig.brand + ' '}
                  immediately of any unauthorized use of your account or
                  password, or any other breach of security. You may be held
                  liable for losses incurred by {siteConfig.brand} or any other
                  user of or visitor to the Site due to someone else using your{' '}
                  {siteConfig.brand} ID, password or account as a result of your
                  failing to keep your account information secure and
                  confidential.
                </p>
                <p>
                  You may not use anyone else&rsquo;s {siteConfig.brand} ID,
                  password or account at any time without the express permission
                  and consent of the holder of that {siteConfig.brand} ID,
                  password or account. {siteConfig.brand} cannot and will not be
                  liable for any loss or damage arising from your failure to
                  comply with these obligations.
                </p>
                <h2>Privacy</h2>
                <p>
                  {siteConfig.brand}&rsquo;s Privacy Policy applies to use of
                  this Site, and its terms are made a part of these Terms by
                  this reference. To view
                  {' ' + siteConfig.brand}&rsquo;s Privacy Policy,{' '}
                  <a href="/privacy-policy" className="link">
                    click here
                  </a>
                  . Additionally, by using the Site, you acknowledge and agree
                  that Internet transmissions are never completely private or
                  secure. You understand that any message or information you
                  send to the Site may be read or intercepted by others, even if
                  there is a special notice that a particular transmission (for
                  example, credit card information) is encrypted.
                </p>
                <h2>Disclaimers</h2>
                <p>
                  {siteConfig.brand.toUpperCase()} DOES NOT PROMISE THAT THE
                  SITE OR ANY CONTENT, SERVICE OR FEATURE OF THE SITE WILL BE
                  ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE
                  CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE SPECIFIC
                  RESULTS. THE SITE AND ITS CONTENT ARE DELIVERED ON AN "AS-IS"
                  AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE SITE
                  IS SUBJECT TO CHANGE WITHOUT NOTICE.{' '}
                  {siteConfig.brand.toUpperCase()} CANNOT ENSURE THAT ANY FILES
                  OR OTHER DATA YOU DOWNLOAD FROM THE SITE WILL BE FREE OF
                  VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES.{' '}
                  {siteConfig.brand.toUpperCase() + ' '}
                  DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY
                  WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND
                  FITNESS FOR A PARTICULAR PURPOSE.{' '}
                  {siteConfig.brand.toUpperCase()} DISCLAIMS ANY AND ALL
                  LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD
                  PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE
                  AND/OR ANY
                  {siteConfig.brand.toUpperCase()} SERVICES. YOU ASSUME TOTAL
                  RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED SITES.
                  YOUR SOLE REMEDY AGAINST {siteConfig.brand.toUpperCase()} FOR
                  DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO STOP USING
                  THE SITE OR ANY SUCH CONTENT. THIS LIMITATION OF RELIEF IS A
                  PART OF THE BARGAIN BETWEEN THE PARTIES.
                </p>
                <p>
                  The above disclaimer applies to any damages, liability or
                  injuries caused by any failure of performance, error,
                  omission, interruption, deletion, defect, delay in operation
                  or transmission, computer virus, communication line failure,
                  theft or destruction of or unauthorized access to, alteration
                  of, or use, whether for breach of contract, tort, negligence
                  or any other cause of action.
                </p>
                <p>
                  {siteConfig.brand} reserves the right to do any of the
                  following, at any time, without notice: (1) to modify, suspend
                  or terminate operation of or access to the Site, or any
                  portion of the Site, for any reason; (2) to modify or change
                  the Site, or any portion of the Site, and any applicable
                  policies or terms; and (3) to interrupt the operation of the
                  Site, or any portion of the Site, as necessary to perform
                  routine or non-routine maintenance, error correction, or other
                  changes.
                </p>
                <h2>Violation of These Terms of Use</h2>
                <p>
                  {siteConfig.brand} may disclose any information we have about
                  you (including your identity) if we determine that such
                  disclosure is necessary in connection with any investigation
                  or complaint regarding your use of the Site, or to identify,
                  contact or bring legal action against someone who may be
                  causing injury to or interference with (either intentionally
                  or unintentionally) {siteConfig.brand}&rsquo;s rights or
                  property, or the rights or property of visitors to or users of
                  the Site. {siteConfig.brand} reserves the right at all times
                  to disclose any information that {siteConfig.brand} deems
                  necessary to comply with any applicable law, regulation, legal
                  process or governmental request.
                  {siteConfig.brand + ' '} also may disclose your information
                  when {siteConfig.brand} determines that applicable law
                  requires or permits such disclosure, including exchanging
                  information with other companies and organizations for fraud
                  protection purposes.
                </p>
                <p>
                  You acknowledge and agree that {siteConfig.brand} may preserve
                  any transmittal or communication by you with{' '}
                  {siteConfig.brand} through the Site or any service offered on
                  or through the Site, and may also disclose such data if
                  required to do so by law or {siteConfig.brand} determines that
                  such preservation or disclosure is reasonably necessary to (1)
                  comply with legal process, (2) enforce these Terms of Use, (3)
                  respond to claims that any such data violates the rights of
                  others, or (4) protect the rights, property or personal safety
                  of {siteConfig.brand}, its employees, users of or visitors to
                  the Site, and the public.
                </p>
                <p>
                  You agree that {siteConfig.brand} may, in its sole discretion
                  and without prior notice, terminate your access to the Site
                  and/or block your future access to the Site if we determine
                  that you have violated these Terms or other agreements or
                  guidelines which may be associated with your use of the Site.
                  You also agree that any violation by you of these Terms will
                  constitute an unlawful and unfair business practice, and will
                  cause irreparable harm to
                  {' ' + siteConfig.brand}, for which monetary damages would be
                  inadequate, and you consent to {siteConfig.brand} obtaining
                  any injunctive or equitable relief that {siteConfig.brand}{' '}
                  deems necessary or appropriate in such circumstances. These
                  remedies are in addition to any other remedies{' '}
                  {siteConfig.brand} may have at law or in equity.
                </p>
                <p>
                  You agree that {siteConfig.brand} may, in its sole discretion
                  and without prior notice, terminate your access to the Site,
                  for cause, which includes (but is not limited to) (1) requests
                  by law enforcement or other government agencies, (2) a request
                  by you (self-initiated account deletions), (3) discontinuance
                  or material modification of the Site or any service offered on
                  or through the Site, or (4) unexpected technical issues or
                  problems.
                </p>
                <p>
                  If {siteConfig.brand} does take any legal action against you
                  as a result of your violation of these Terms,{' '}
                  {siteConfig.brand} will be entitled to recover from you, and
                  you agree to pay, all reasonable attorneys&rsquo; fees and
                  costs of such action, in addition to any other relief granted
                  to
                  {' ' + siteConfig.brand}. You agree that {siteConfig.brand}{' '}
                  will not be liable to you or to any third party for
                  termination of your access to the Site as a result of any
                  violation of these Terms.
                </p>
                <h2>Governing Law</h2>
                <p>
                  You agree that all matters relating to your access to or use
                  of the Site, including all disputes, will be governed by the
                  laws of the United States and by the laws of the State of
                  Arizona without regard to its conflicts of laws provisions.
                  You agree to the personal jurisdiction by and venue in the
                  state and federal courts in Maricopa County, Arizona, and
                  waive any objection to such jurisdiction or venue.
                </p>
                <h2>Dispute Resolution</h2>
                <p>
                  In the event of any controversy or dispute between{' '}
                  {siteConfig.brand} and you arising out of or in connection
                  with your use of the Site, the parties shall attempt, promptly
                  and in good faith, to resolve any such dispute. To the extent
                  that you and {siteConfig.brand} are unable to resolve any
                  disputes such disputes will be submitted to a mediator located
                  in Maricopa County, Arizona prior to any arbitration or other
                  action.&nbsp; The mediator&rsquo;s fees and expenses shall be
                  shared equally by you and {siteConfig.brand}, who agree to
                  exercise their best efforts in good faith to resolve all
                  disputes in mediation.&nbsp; Mediation begins on the date one
                  party sends written notice to the other requesting mediation
                  and presenting in the notice the matter to be mediated.&nbsp;
                  Mediation shall conclude when both parties sign an agreement
                  that resolves the subject of the mediation.&nbsp; If no
                  agreement is reached within sixty (60) calendar days after the
                  date of the original written notice, the mediation is
                  considered unsuccessful.&nbsp; In the event any dispute cannot
                  be settled by you and {siteConfig.brand} informally or through
                  mediation, the matter in controversy or dispute shall be
                  resolved by binding confidential private arbitration before a
                  mutually agreeable arbitrator in Maricopa County,
                  Arizona.&nbsp; In the event the parties cannot agree on an
                  arbitrator, the presiding judge of the Superior Court of
                  Maricopa County, Arizona, shall decide who the arbitrator
                  shall be.&nbsp; The decision of the arbitrator will be final
                  and binding on the parties to such proceeding.&nbsp; In any
                  arbitration or other proceeding related to a dispute, the
                  prevailing party shall be entitled to reimbursement of such
                  party&rsquo;s reasonable costs and attorney&rsquo;s
                  fees.&nbsp; Otherwise, the arbitrator shall have the
                  discretion to enter any award permissible under applicable
                  law, and such award shall be enforceable exclusively in a
                  state or federal court of competent jurisdiction in Maricopa
                  County, Arizona. Any claim under these Terms must be brought
                  within one (1) year after the cause of action arises, or such
                  claim or cause of action is barred.
                </p>
                <h2>Limitation of Liability</h2>
                <p>
                  Except where prohibited by law, in no event will{' '}
                  {siteConfig.brand} be liable to you for any indirect,
                  consequential, exemplary, incidental or punitive damages,
                  including lost profits, even if {siteConfig.brand} has been
                  advised of the possibility of such damages.
                </p>
                <p>
                  If, notwithstanding the other provisions of these Terms,{' '}
                  {siteConfig.brand} is found to be liable to you for any damage
                  or loss which arises out of or is in any way connected with
                  your use of the Site or any Content,
                  {' ' + siteConfig.brand}&rsquo;s liability shall in no event
                  exceed the greater of (1) the total of any subscription or
                  similar fees with respect to any service or feature of or on
                  the Site paid in the six months prior to the date of the
                  initial claim made against {siteConfig.brand}, or (2)
                  US$100.00. Some jurisdictions do not allow limitations of
                  liability, so the foregoing limitation may not apply to you.
                </p>
                <h2>Indemnity</h2>
                <p>
                  You agree to indemnify and hold {siteConfig.brand}, its
                  officers, directors, shareholders, predecessors, successors in
                  interest, employees, agents, subsidiaries and affiliates,
                  harmless from any demands, loss, liability, claims or expenses
                  (including attorneys&rsquo; fees), made against{' '}
                  {siteConfig.brand} by any third party due to or arising out of
                  or in connection with your use of the Site.
                </p>
                <h2>Void Where Prohibited</h2>
                <p>
                  {siteConfig.brand} administers and operates the{' '}
                  <a href={'https://www.' + siteConfig.domain} className="link">
                    www.
                    {siteConfig.domain}
                  </a>{' '}
                  Site from its location in Phoenix, Arizona USA. Although the
                  Site is accessible worldwide, not all features, products or
                  services discussed, referenced, provided or offered through or
                  on the Site are available to all persons or in all geographic
                  locations, or appropriate or available for use outside the
                  United States. {siteConfig.brand + ' '}
                  reserves the right to limit, in its sole discretion, the
                  provision and quantity of any feature, product or service to
                  any person or geographic area. Any offer for any feature,
                  product or service made on the Site is void where prohibited.
                  If you choose to access the Site from outside the United
                  States, you do so on your own initiative and you are solely
                  responsible for complying with applicable local laws.
                </p>
                <h2>
                  Notification of Copyright Infringement Under the Digital
                  Millennium Copyright Act (DMCA)
                </h2>
                <p>
                  {siteConfig.brand} does not knowingly permit copyright
                  infringing activities on the Site.
                </p>
                <p>
                  If you believe that your copyrighted material may have been
                  infringed, please provide us with the following information in
                  writing:
                </p>
                <ul>
                  <li>
                    A physical or electronic signature of a person authorized to
                    act on behalf of the owner of an exclusive right that is
                    allegedly infringed.
                  </li>
                  <li>
                    Identification of the copyrighted work claimed to have been
                    infringed, or, if multiple copyrighted works at a single
                    online site are covered by a single notification, a
                    representative list of such works at that site.
                  </li>
                  <li>
                    Identification of the material that is claimed to be
                    infringing or to be the subject of infringing activity and
                    that is to be removed or access to which is to be disabled
                    and information reasonably sufficient to permit{' '}
                    {siteConfig.brand} to locate the material. Providing URLs
                    for the location of the allegedly infringing material or
                    activity is the best way to help us locate content quickly.
                  </li>
                  <li>
                    Information reasonably sufficient to permit{' '}
                    {siteConfig.brand} to contact you, the complaining party,
                    such as an address, telephone number, and, if available, an
                    electronic mail address at which you may be contacted.
                  </li>
                  <li>
                    A statement that you, the complaining party, have a good
                    faith belief that use of the material in the manner
                    complained of is not authorized by the copyright owner, its
                    agent, or the law.
                  </li>
                  <li>
                    A statement that the information in the notification of
                    claimed infringement is accurate and, under penalty of
                    perjury, that you, the complaining party, are the copyright
                    owner or authorized to act on behalf of the owner of an
                    exclusive right that is allegedly infringed.
                  </li>
                </ul>
                <p>
                  Please be aware that submitting a report of intellectual
                  property infringement is a serious matter with potential legal
                  consequences. For example, intentionally submitting a
                  misleading or fraudulent report may lead to liability for
                  damages under 17 U.S.C. &sect; 512(f), or similar laws in
                  other countries.
                </p>
                <p>
                  Before submitting a notice of infringement, be sure to
                  consider whether fair use or a similar exception to copyright
                  or trademark law may apply to the use. If you are unsure
                  whether the content you are considering reporting infringes
                  your rights, you may wish to seek legal guidance before
                  submitting a notice of infringement.
                </p>
                <p>
                  Do not submit a notice of infringement unless you are the
                  owner of the copyrighted material or trademark that you
                  believe is being infringed, or an agent authorized to act on
                  the owner's behalf.
                </p>
                <p>
                  The fastest way for us to process your notification of claimed
                  infringement is via the{' '}
                  <a href="/copyright-form/" className="link">
                    online form here
                  </a>
                  . The slower method to reach our designated agent and for
                  processing is to send a proper and complete notification of
                  claimed infringement to the mailing address listed below.
                </p>
                <p>
                  DCMA Agent Mailing Address:
                  <br /> {siteConfig.brand} LLC
                  <br /> Attn: DCMA Agent
                  <br /> 2525 E Camelback Road STE 810
                  <br /> Phoenix, AZ 85016
                </p>
                <h2>Miscellaneous</h2>
                <p>
                  You may not use or export or re-export any Content or any copy
                  or adaptation of such Content, or any product or service
                  offered on the Site, in violation of any applicable laws or
                  regulations, including without limitation United States export
                  laws and regulations.
                </p>
                <p>
                  If any of the provisions of these Terms are held by a court or
                  other tribunal of competent jurisdiction to be void or
                  unenforceable, such provisions shall be limited or eliminated
                  to the minimum extent necessary and replaced with a valid
                  provision that best embodies the intent of these Terms, so
                  that these Term shall remain in full force and effect.
                </p>
                <p>
                  These Terms constitute the entire agreement between you and{' '}
                  {siteConfig.brand + ' '}
                  with regard to your use of the Site, and any and all other
                  written or oral agreements or understandings previously
                  existing between you and {siteConfig.brand} with respect to
                  such use are hereby superseded and cancelled.
                </p>
                <p>
                  {siteConfig.brand}&rsquo;s failure to insist on or enforce
                  strict performance of these Terms shall not be construed as a
                  waiver by {siteConfig.brand} of any provision or any right it
                  has to enforce these Terms, nor shall any course of conduct
                  between {siteConfig.brand} and you or any other party be
                  deemed to modify any provision of these Terms. These Terms
                  shall not be interpreted or construed to confer any rights or
                  remedies on any third parties.
                </p>
                <p>
                  Click here to{' '}
                  <a href="/contact" className="link">
                    Contact Us
                  </a>{' '}
                  if you have any questions or concerns regarding these Terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
